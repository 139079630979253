export const translationsRu = {
  header: {
    cabinet: "Личный кабинет",
    logIn: "Вход"
  },
  menu: {
    buyProxy: "Купить прокси",
    proxyFor: "Прокси для",
    prices: "Цены",
    company: "Компания",
    instruments: "Инструменты"
  },
  notifications: {
    title: "Уведомления",
    clear: "Очистити все",
    new: "НОВОЕ",
    expand: "Развернуть",
    collapse: "Cвернуть",
    noNotifications: "Уведомлений нет"
  },
  company: {
    titles: {
      aboutUs: "О нас",
      information: "Информация",
      other: "Другое"
    },
    links: {
      conditions: "Условия",
      faq: "FAQ",
      contacts: "Контакты",
      reviews: "Отзывы",
      promocodes: "Промокоды",
      blog: "Блог",
      cabinet: "Личный кабинет",
      offer: "Публичная оферта",
      policy: "Политика конфиденциальности",
      refundPolicy: "Политика возврата",
      cookiePolicy: "Политика Cookie",
      documentation: "Документация"
    }
  },
  tools: {
    myIp: { label: "Мой IP" },
    pingIp: { label: "Пинг IP" },
    checkEvercookie: { label: "Проверка Evercookie" },
    proxyChecker: { label: "Прокси-чекер" },
    myAnonymity: { label: "Моя анонимность" },
    checkWebrtc: { label: "Проверка WebRTC" },
    portScanner: { label: "Сканер портов" },
    fingerprintScanner: { label: "Сканер Fingerprint" },
    ipTracing: { label: "Трассировка IP" }
  },
  cabinet: {
    profile: { label: "Профиль" },
    orders: {
      label: "Заказы",
      sub: {
        ordersIpv4: "IPv4",
        ordersIpv6: "IPv6",
        ordersIsp: "ISP",
        ordersMobileProxy: "Мобильный прокси",
        ordersNotActive: "Не активные разделы"
      },
      status: {
        active: "Активен"
      },
      noData: "Нет заказов",
      extendError: "Произошла ошибка при продлении прокси"
    },
    newOrder: { label: "Новый заказ" },
    balance: {
      label: "Баланс",
      noData: "Нет истории пополнений"
    },
    paymentHistory: {
      label: "История платежей",
      success: "Оплачено",
      notSuccess: "Не оплачено",
      noData: "Нет платежей"
    },
    actionsHistory: {
      label: "История действий",
      noData: "Нет истории действий"
    },
    promocodes: {
      label: "Промокоды",
      noData: "Промокоды не найдены"
    },
    subscription: { label: "Подписка" },
    faq: { label: "FAQ" },
    developersApi: { label: "API" },
    logout: { label: "Выход" }
  },
  languages: {
    ru: "Рус",
    en: "Eng",
    uk: "Укр"
  },
  countries: {
    all: "Все",
    RUS: "Россия",
    USA: "США",
    UKR: "Украина",
    KAZ: "Казахстан",
    GBR: "Англия",
    DEU: "Германия",
    BGR: "Болгария",
    FRA: "Франция",
    NLD: "Нидерланды",
    BLR: "Беларусь",
    MDA: "Молдавия",
    LVA: "Латвия",
    IND: "Индия",
    POL: "Польша",
    ITA: "Италия",
    LKA: "Шри-Ланка",
    AUT: "Австрия",
    AUS: "Австралия",
    JPN: "Япония",
    THA: "Таиланд",
    CAN: "Канада",
    MEX: "Мексика",
    ESP: "Испания",
    GEO: "Грузия",
    EGY: "Египет"
  },
  authMethods: {
    ip: "Статический IP",
    login: "Логин/Пароль"
  },
  proxySocials: {
    vk: "Вконтакте",
    classmates: "Одноклассники",
    telegram: "Telegram",
    linkedin: "LinkedIn",
    facebook: "Facebook",
    instagram: "Instagram",
    twitter: "Twitter",
    youtube: "YouTube",
    periscope: "Periscope"
  },
  contacts: {
    skype: "YouProxy",
    telegram: "Telegram",
    vk: "Vkontakte",
    telegramChannel: "Telegram Channel",
    facebook: "Facebook",
    facebookGroup: "Facebook Group",
    mail: "support@youproxy.io",
    fields: {
      name: "Ваше имя",
      email: "Ваш email",
      text: "Ваше сообщение"
    },
    validateErrors: {
      name: {
        required: "Имя обязательно"
      },

      email: {
        required: "Email обязателен",
        invalid: "Некорректный email"
      },

      text: {
        required: "Сообщение обязательно"
      }
    },
    serverSuccess: {
      title: "Письмо отправлено",
      description: "В ближайшее время наш менеджер ответит на все интересующие Вас вопросы.",
      action: "Вернуться назад"
    },
    serverError: {
      title: "Письмо не отправлено",
      description:
        "Что-то пошло не так, по независящим от нас причинам письмо не было доставлено. ",
      action: "Вернуться назад"
    },
    serverNotRegistered: {
      title: "Этот email не зарегистрирован",
      description: "Чтобы отправить письмо с этим email, вам нужно сначала зарегистрироваться.",
      action: "Вернуться назад"
    },
    serverNotValidMail: {
      title: "Пожалуйста, введите существующий email",
      description:
        "Сообщение не было отправлено, поскольку введенный адрес электронной почты не существует.",
      action: "Вернуться назад"
    }
  },
  payments: {
    usd: "$",
    eur: "EUR",
    mbtc: "mBTC",
    uah: "UAN",
    rub: "RUB"
  },
  buyProxy: {
    ipv4: "IPv4",
    ipv6: "IPv6",
    isp: "ISP",
    mobile: "Мобильные прокси"
  },
  proxyFor: {
    socialNetworks: "Социальные сети",
    webScraping: "Парсинг страниц",
    gamingProxies: "Онлайн игры",
    sneaker: "Прокси sneaker",
    onlineMarketplace: "Онлайн-маркет",
    other: "Другое",
    searchEngineParsing: "Парсинг поисковой системы"
  },
  mobileProxy: {
    beeline: "Билайн",
    vodaphone: "Водафон",
    kievstar: "Киевстар",
    life: "Лайф",
    mts: "МТС"
  },
  faq: {
    generalQuestions: "Общие вопросы",
    extendProxy: "Продление прокси",
    technicalQuestions: "Тех. вопросы",
    security: "Безопасность",
    authorizationSettings: "Авторизация/Настройка"
  },
  advantages: {
    countryOwnership: "Проверка IPv4, IPv6 на принадлежность страны;",
    protocolDefinition: "Определение типа протокола HTTP/S, SOCKS5 и текущей скорости;",
    anonymousChecking: "Проверка анонимности адреса;",
    proxyChecking: "Групповая, скоростная многопоточная проверка прокси в 64 потока;",
    downloadUpload: "Возможность закачать списком и выгрузить результат проверки;",
    resultsFilter: "Удобный вывод результатов с помощью фильтра отображения."
  },
  portType: {
    popularPorts: "Популярные порты",
    hiddenPorts: "Скрытые порты",
    proxyPorts: "Прокси порты"
  },
  answers: {
    yes: "Да",
    no: "Нет"
  },
  proxyGoal: {
    goal1: "Цель прокси",
    goal2: "Цель прокси 2",
    goal3: "Цель прокси 3"
  },
  proxyServices: {
    service1: "Услуга прокси",
    service2: "Услуга прокси 2",
    service3: "Услуга прокси 3"
  },
  proxyRentalPeriod: {
    day: "Дней"
  },
  ipType: {
    all: "Все",
    https: "HTTPS",
    socks5: "SOCKS5",
    http: "HTTP"
  },
  pages: {
    app: {
      home: {
        hero: {
          heading: "Купить высокоскоростные приватные <span>прокси</span>",
          totalSum: "Стоимость составляет: "
        },
        geos: {
          heading: "Купить прокси интересующей гео"
        },
        features: {
          heading: "Особенности",
          description:
            "У Youproxy вы покупаете исключительно приватные прокси сервера с поддержкой обоих протоколов: Socks 5 и HTTP(s). Также, основные характеристики нашего сервиса мы перечисляем ниже.",
          leftItems: {
            security: {
              label: "Защита",
              value: "100%"
            },
            uptime: {
              label: "Uptime",
              value: "99%"
            },
            delivery: {
              label: "Выдача",
              value: "3 мин"
            },
            support: {
              label: "Поддержка",
              value: "24/7"
            }
          },
          rightItems: {
            coverage:
              "Можно купить прокси в <span>20+ странах</span>, пул которых постоянно обновляется и расширяется",
            speed: "<span>высокая скорость</span>",
            networks:
              "Купленные прокси выдаются с разбросом <span>по сетям</span> и <span>подсетям</span>",
            fullPersonal: "К покупке предлагаются прокси на 100% <span>персональные</span>"
          },
          downloadListCard: {
            title: "Список загрузки (TXT)",
            description:
              "Наслаждайтесь использованием современной панели управления после загрузки списка прокси. Каждый прокси-план предлагает несколько ценных и полезных возможностей.",
            button: {
              text: "Исследуйте"
            }
          },
          apiCard: {
            title: "API",
            description:
              "Оптимизируйте свой рабочий процесс и бюджет за счет интеграции с API RESTful Webshare. Webshare предлагает самые надежные API прокси.",
            button: {
              text: "Посмотреть API"
            }
          }
        },
        form: {
          minimal: "Минимальное кол-во прокси: "
        },
        seo: ""
      },
      proxyFor: {
        hero: {
          heading: "Прокси для",
          caption: "Прокси поштучно:"
        },
        packet: {
          heading: "Пакетные прокси",
          table: {
            goals: "Цели:",
            countPrice: "Количество/стоимость:",
            actions: {
              order: "Заказать"
            }
          }
        },
        seo: ""
      },
      proxyByCountry: {
        prices: {
          title: "Пакетные прокси",
          description:
            "Прокси {{country}} выдаются исключительно в одни руки с поддержкой обоих протоколов: Socks5 и HTTP/A7 В поштучной форме можно оформить купить любое количество с учётом всех предусмотренных скидок за количество и срок аренды"
        },
        whatYouGet: {
          title: "Что вы получаете",
          content: [
            "Авторизация по API",
            "Выгрузка в любом формате",
            "Стабильный UP'time",
            "Высокий траст показатель",
            "Разнообразие сетей/подсетей"
          ]
        },
        apiIntegration: {
          title: "Интегрируй API",
          description:
            "Прокси {{country}} выдаются исключительно в одни руки с поддержкой обоих протоколов: Socks5 и HTTP/A7 В поштучной форме можно оформить купить любое количество с учётом всех предусмотренных скидок за количество и срок аренды"
        }
      },
      buyProxy: {
        hero: {
          heading: "Прокси",
          caption: "Прокси поштучно:",
          description:
            "Прокси {{countryName}} выдаются исключительно в одни руки с поддержкой обоих протоколов: Socks5 и HTTP/A7 В поштучной форме можно оформить купить любое количество с учётом всех предусмотренных скидок за количество и срок аренды",
          adv: {
            server: "<span>HTTP(S)</span> / SOCKS5",
            atom: "<span>170+</span> подсетей",
            support: "<span>Поддержка</span> <br /> 24/7/365"
          },
          animationLabels: {
            noLimits: "Без лимитов",
            support: "Поддержка 24/7",
            connection: "Стабильное соединение",
            anonymity: "Анонимность"
          }
        },
        packet: {
          heading: "Пакетные прокси:",
          table: {
            goals: "Цели:",
            countPrice: "Количество/стоимость:",
            actions: {
              order: "Заказать"
            },
            searchLabel: "Прокси для {{proxyFor}} по ГЕО:"
          }
        }
      },
      mobileProxy: {
        hero: {
          heading: "Мобильные прокси",
          description:
            "Динамические мобильные прокси - бесперебойный серфинг любой сложности, без блокировок и капча. IP адреса реальных GSM операторов, что обеспечивает максимальную анонмность и безопасность.",
          caption: "ЦЕНЫ МОБИЛЬНЫХ LTE ПРОКСИ:",
          support: "Онлайн поддержка",
          order: {
            info: {
              characteristics: "Характеристики:",
              country: "Страна:",
              proxyCount: "Количество прокси:",
              dedicatedIp: "Выделенный IP:",
              multipleSubnets: "Несколько подсетей:",
              note: {
                label: "Примечание:",
                content: ""
              }
            },
            result: {
              title: "Опции заказа",
              proxy: "Прокси:",
              rentalPeriod: "Срок:"
            }
          }
        },
        seo: ""
      },
      blog: {
        heading: "Блог",
        noData: "Посты в блоге не найдены"
      },
      article: {
        sidebar: {
          new: "Новое",
          popular: "Популярное"
        },
        noData: "Извините, статья не найдена"
      },
      notFound: {
        title: "Упс... Кажется такой страницы нет.",
        text: "Возможно страница была удалена или недоступна временно. Пожалуйста, обратитесь к нашим менеджерам если вам требуется с чем либо помощь.",
        btn: "Вернуться на главную"
      },
      reviews: {
        hero: {
          heading: "Отзывы",
          sources: "Источники",
          showAll: "Показать все отзывы",
          allReviews: "Все отзывы"
        },
        noData: "Отзывы не найдены"
      },
      promocodes: {
        heading: "Промокоды",
        more: "Показать ещё",
        link: "Использовать промокод",
        validDate: "Действительный до",
        used: "Использован",
        times: "раз",
        noData: "Извините, промокоды не найдены"
      },
      contacts: {
        heading: "Контакты",
        form: "Форма обратной связи",
        description:
          "Мы рады получать от вас отзывы, предложения и вопросы касательно нашего сервиса. Наш менеджер рассмотрит вашу заявку и даст ответ в ближайшее время."
      },
      thanks: {
        hero: {
          heading: "Спасибо за покупку!",
          subHeading: "Оплата прошла успешно. Спасибо что выбрали наш сервис!",
          info: "Мы приняли Ваш заказ и наша система подключает IP адреса. В основном IP адреса доступны через 5-10 минут. Регистрация происходит автоматически, после покупки прокси - данные отправляются на указанную в заказе почту и отображаются в личном кабинете.",
          description: `
            <p>
              Всю информацию касательно вашего заказа, можете посмотреть в личном кабинете. Так же
              отслеживать статусы заказов, время действия IP адресов и управлять операциями.
            </p>
            <p>
              Если у Вас возникнут проблемы с подключением прокси, обратитесь к оператору для
              получения помощи.
            </p>
          `
        },
        replenishmentHero: {
          heading: {
            success: "Спасибо за оплату!",
            pending: "Ваш платеж обрабатывается!",
            cancel: "Ваш платеж был отменен"
          },
          subHeading: "Пополнение на сумму: {amount}",
          info: {
            success:
              "Оплата прошла успешно. Средства добавятся к вашему балансу в течение 5-10 минут.",
            pending:
              "Ваш платеж всё ещё обрабатывается. Пожалуйста, подождите пока баланс не обновится в личном кабинете",
            cancel:
              "Похоже, что ваша оплата была отменена. Пожалуйста, свяжитесь со своим банком и попробуйте еще раз."
          },
          description: `
            <p>
              Всю информацию касательно вашего баланса, можете посмотреть в личном кабинете.
            </p>
            <p>
              Если у Вас возникнут проблемы с вашим балансом, обратитесь к оператору для
              получения помощи.
            </p>
          `
        },
        contacts: {
          heading: "Будем благодарны за мнение",
          description:
            "Оставьте отзыв на любом из ресурсов о нашей работе, укажите минусы и плюсы в работе с нами. Мы учитываем каждое мнение, исправляем ошибки, чтобы стать лучше для вас!"
        }
      },
      balanceThanks: {
        hero: {
          success: {
            heading: "Ваш баланс пополнен!",
            subHeading: "Ваш баланс пополнен на: {amount}$",
            info: "",
            description: `
              <p>
                Вся информация о вашем балансе находится в личном кабинете.
                Так же вы можете посмотреть вашу историю пополнений.
              </p>
              <p>
                Если у вас возникли проблемы, связанные с балансом, обратитесь к нашему оператору за помощью.
              </p>
            `
          },

          cancel: {
            heading: "Ваш платеж был отменен!",
            subHeading: "Возможно, возникла проблема с вашим банком, попробуйте еще раз.",
            info: "",
            description: `
              <p>
                Вся информация о вашем балансе находится в личном кабинете.
                Так же вы можете посмотреть вашу историю пополнений.
              </p>
              <p>
                Если у вас возникли проблемы, связанные с балансом, обратитесь к нашему оператору за помощью.
              </p>
            `
          },

          pending: {
            heading: "Ваш платеж обрабатывается!",
            subHeading: "Новый баланс появится через некоторое время.",
            info: "",
            description: `
              <p>
                Вся информация о вашем балансе находится в личном кабинете.
                Так же вы можете посмотреть вашу историю пополнений.
              </p>
              <p>
                Если у вас возникли проблемы, связанные с балансом, обратитесь к нашему оператору за помощью.
              </p>
            `
          }
        }
      },
      price: {
        heading: "Цены на прокси",
        location: "Локация"
      },
      offer: {
        heading: "",
        content: ""
      },
      conditions: {
        heading: "",
        content: ""
      },
      policy: {
        heading: "",
        content: ""
      },
      refundPolicy: {
        heading: "",
        content: ""
      },
      cookiePolicy: {
        heading: "",
        content: ""
      }
    },
    cabinet: {
      profile: {
        heading: "Профиль"
      },
      orders: {
        tags: {
          ipv4: {
            heading: "Прокси IPv4"
          },
          ipv6: {
            heading: "Прокси IPv6"
          },
          isp: {
            heading: "Прокси ISP"
          },
          "mobile-proxy": {
            heading: "Мобильные прокси"
          },
          "inactive-partitions": {
            heading: "Заказы"
          }
        },
        widgets: {
          system: {
            title: "Активные заказы:",
            rowLabel: "Прокси "
          },
          export: {
            title: "Экспорт:",
            chooseFormat: "Выберите формат:",
            downloadList: "Выгрузить список",
            selectProxies: "Пожалуйста, выберите прокси для экспорта",
            selectSOCKS: "Пожалуйста, выберите прокси с протоколом SOCKS5",
            selectHTTP: "Пожалуйста, выберите прокси с протоколом HTTPS"
          },
          autoRenewal: {
            title: "Автопродление",
            description: "Выберите из списка IP для включения или отключения автопродления.",
            selectedIp: "Выбрано IP",
            enable: "Включить",
            disable: "Отключить",
            selectIpsWithSameSettings: "Выберите IP с одинаковыми настройками",
            willCharged: "будет списано",
            alert: {
              priceError: "Ошибка в расчете стоимости, выберите другой IP.",
              notEnoughMoney: "Суммы на балансе недостаточно для использования опции. Пожалуйста,",
              topUpBalance: "пополните баланс на",
              enabled: "Автопродление включено",
              notEnabled: "Не удалось включить автопродление",
              disabled: "Автопродление отключено",
              notDisabled: "Не удалось отключить автопродление"
            }
          },
          autoRenewalModal: {
            title: "Включение автопродления",
            descriptionEnableAR:
              "Выберите срок, на который будут продлеваться выбранные IP адреса, и завершите процедуру включения опции.",
            term: "Срок",
            paymentMethod: "Способ оплаты",
            confirm: "Подтвердить",
            cancel: "Отменить",
            disableAR: "Отключить автопродление у IP",
            descriptionDisableAR:
              "Прокси будут активны до конца срока действия и не будут продлены на следующий период"
          },
          exportModal: {
            description: "Пожалуйста, используйте шаблон для выгрузки ваших прокси."
          }
        },
        table: {
          issueDate: "Дата выдачи",
          expirationDate: "Дата окончания",
          ip: "IP",
          activeFrom: "Активен с",
          activeBy: "Активен до",
          ipStatus: "Статус IP",
          reboot: "Ротация",
          everyTimeMinute: "каждые {{time}} мин.",
          goal: "Цель",
          protocol: "Протокол",
          port: "Порт",
          portSocks: "Socks порт",
          portProxy: "Proxy порт",
          status: "Статус IP",
          ACTIVE: "Активен"
        }
      },
      newOrder: {
        heading: "Новый заказ"
      },
      balance: {
        heading: "Ваш баланс",
        form: {
          heading: "Пополнение баланса",
          inputLabel: "Сумма пополнение",
          inputPlaceholder: "Введите сумму",
          selectLabel: "Способ оплаты",
          warning: "Некоторые платежные способы могут взымать дополнительную комиссию при оплате",
          btn: "Продолжить"
        },
        table: {
          orderDate: "Дата",
          orderSum: "Сумма",
          orderStatus: "Статус",
          payAction: "Оплатить",
          noData: "Транзакции не найдены"
        },
        options: {
          fillBalance: "Пополнение баланса",
          historyTransactions: "История транзакций"
        },
        status: {
          success: "Успешно",
          failed: "Не успешно"
        }
      },
      paymentHistory: {
        heading: "История платежей",
        table: {
          orderCompound: "Состав заказа:",
          amountCurrency: "Сумма/Валюта:",
          status: "Статус:",
          buy: "Оплатить"
        },
        paymentActions: {
          ORDER_BUY: "Покупка прокси",
          ORDER_EXTEND: "Продление прокси",
          REPLENISHMENT: "Пополнение баланса"
        }
      },
      actionsHistory: {
        heading: "История действий",
        table: {
          replacementDate: "Дата замены:",
          replacementTime: "Время замены:",
          replacedWith: "Заменен на:"
        }
      },
      promocodes: {
        heading: "Промокоды",
        more: "Показать ещё"
      },
      subscription: {
        heading: "Подписка",
        successMessage:
          "Подписки изменены успешно. Пожалуйста, проверьте свой email для подтверждения",
        errorMessage: "Произошла ошибка"
      },
      developersApi: {
        heading: "API",
        description: {
          apiKey: "Не передавайте этот ключ никому.",
          accessIpAddresses:
            "Доступ к API возможен только с определенных IP-адресов. Вы можете указать до 3 IP-адресов через запятую. Обратите внимание, что в течение 1 минуты допускается не более 60 запросов API. Если лимит превышен? дополнительные запросы приведут к сообщению об ошибке."
        },
        documentation: {
          label: "Читать документацию",
          read: "Читать"
        }
      }
    },
    tools: {
      myIp: {
        heading: "Мой IP",
        description: `
          <p>
            Скройте свой IP-адрес с персональными прокси от Proxy-IPv4! Защитите личные данные,
            оставайтесь анонимными, скрывайте свое местоположение и обходите блокировки сайтов с
            прокси-серверами!
          </p>
          <p>И это лишь малая часть информации, которую можно отследить по вашему IP-адресу:</p>
        `,
        seo: "",
        table: {
          yourComputerName: "Имя вашего компьютера:",
          operatingSystem: "Операционная система:",
          yourBrowser: "Ваш браузер:",
          yourLocation: "Ваше местоположение:",
          yourProvider: "Ваш провайдер:",
          proxy: "Прокси:",
          dataProtection: "Защита данных:",
          ipInBlacklists: "IP в Blacklists:"
        }
      },
      pingIp: {
        heading: "Пинг IP",
        description: `
          <p>
            Нажмите на кнопку «Вставить мой IP-адрес» или используйте тот IP или сайт к которому
            необходимо проверить пинг. Выберите 3–5 тестовых пакетов и нажмите кнопку «Пинг IP».
            Вы увидите нужное значение в разделе «time=».
          </p>
          <p>
            Пинг считается нормальным, когда находится в пределах 40-110 мс. От 110-210 мс вы
            начнете испытывать проблемы в онлайн-играх, но на посещении сайтов это практически не
            отразится. Если таймаут превышает 110 мс, то это повод задуматься о качестве работы
            вашего провайдера.
          </p>
        `,
        note: "* Если результат - «Хост не работает», значит, выполняется проверка брандмауэра или маршрутизатора и блокирующие IP-адреса.",
        seo: ""
      },
      checkEvercookie: {
        heading: "Проверка Evercookie",
        description: `
          <p>
            Нажмите кнопку, чтобы создать evercookie. Ваша анонимность не будет нарушена: cookie -
            это случайное число от 1 до 1000, которое мы используем для тестирования evercookies.
            Мы не отслеживаем вас с его помощью.
          </p>
        `,
        createEvercookie: "Создать Evercookie",
        searchEvercookie: "Поиск Evercookie",
        seo: ""
      },
      proxyChecker: {
        heading: "Прокси-чекер",
        description: `
          <p>
            Прокси чекер онлайн для проверки валидности прокси-серверов. Анализ прокси или группы
            адресов на доступность, страну, скорость, анонимность, тип. Прокси чекер онлайн для
            проверки валидности прокси-серверов. Анализ прокси или группы адресов на доступность,
            страну, скорость, анонимность, тип.
          </p>
        `,
        proxyList: "Список прокси",
        table: {
          ip: "IP адрес:",
          port: "Порт:",
          country: "Страна:",
          speed: "Скорость:",
          type: "Тип:",
          anonymity: "Анонимность:",
          state: "Состояние:"
        },
        formatNote: {
          label: "В каком формате добавлять прокси в чекер?",
          publicText:
            "Если у вас <b>публичные прокси</b> (без логина и пароля), то <b>IP:PORT</b> ",
          privateText:
            "Если у вас <b>приватные прокси</b> (с авторизацией по логину и паролю), то <b>USER:PASS@IP:PORT</b> ",
          psText: "Если вы покупали у нас прокси, то они приватные!"
        },
        seo: ""
      },
      myAnonymity: {
        heading: "Моя анонимность",
        widgets: {
          caption: "Все посещённые вами сайты, определяют ваши данные как:"
        },
        description:
          "Мы можем проверить точность этой информации, на самом ли деле она соответствует действительности, не используете ли вы прокси, анонимайзер, VPN сервер, Tor или другие средства анонимизации.",
        seo: "",
        table: {
          httpProxyHeaders: "Заголовки HTTP proxy:",
          openHttpProxyPorts: "Открытые порты HTTP proxy:",
          openWebProxyPorts: "Открытые порты web proxy:",
          openVpnPorts: "Открытые порты VPN:",
          suspiciousHostname: "Подозрительное название хоста:",
          differenceInTimeZonesBrowserAndIp: "Разница во временных зонах (браузера и IP):",
          ipAffiliationWithTheTorNetwork: "Принадлежность IP к сети Tor:",
          browserTurboMode: "Режим браузера Turbo:",
          affiliationWithIpHostingProvider: "Принадлежность IP хостинг провайдеру:",
          webProxyDefinitionJsMethod: "Определение web proxy (JS метод):",
          tunnelDefinitionTwoWayPing: "Определение туннеля (двусторонний пинг):",
          dnsLeak: "Утечка DNS:",
          vpnFingerprint: "VPN fingerprint:",
          ipLeakViaWebRTC: "Утечка IP через WebRTC:",
          ipInBlacklists: "IP в Blacklists:"
        }
      },
      checkWebrtc: {
        heading: "Проверка WebRTC",
        description: `
          <p>
            Проверка на утечку через WebRTC. Ваши данные могут быть под угрозой, даже если вы
            подключены к VPN.
          </p>
        `,
        widgets: {
          ip: "IP адрес:",
          type: "Тип:",
          status: "Статус:"
        },
        restartTest: "Перезапустить тест на утечку WebRTC",
        table: {
          ip: "IP адрес:",
          type: "Тип:",
          status: "Статус:"
        },
        seo: ""
      },
      portScanner: {
        heading: "Сканер портов",
        description: `
          <p>
            Проверка открытых портов с помощью сканера показывает какие порты открыты на вашем или
            чужом компьютере/сайте и за что они отвечают. Проверка производится через сканер nmap и
            показывает, насколько открыт ваш доступ к пк/сайту для внешнего мира.Проверка
            производится через сканер nmap и показывает, насколько открыт ваш доступ к пк/сайту для
            внешнего мира.
          </p>
        `,
        note: "* Если результат - «Хост не работает», значит, выполняется проверка брандмауэра или маршрутизатора и блокирующие IP-адреса.",
        optionsLabel: "Типы сканируемых портов:",
        seo: ""
      },
      fingerprintScanner: {
        heading: "Сканер Fingerprint браузера",
        description: `
          <p>
            Отпечатки браузера представляют собой уникальный слепок с настроек вашего компьютера, а
            специальные механизмы сайтов могут проводить слежение за вами.
          </p>
        `,
        widgets: {
          fingerprint: "Отпечаток браузера (Browser Fingerprint):"
        },
        seo: "",
        table: {
          location: "Расположение:",
          dns: "DNS:",
          provider: "Провайдер:",
          host: "Host:",
          os: "OS:",
          browser: "Браузер:",
          plugin: "Плагин:",
          status: "Статус:",
          userAgent: "User Agent:",
          userAgentJs: "User Agent (метод JS):"
        }
      },
      ipTracing: {
        heading: "Трассировка IP",
        description: `
          <p>
            Благодаря сервису, который размещен на этой странице, вам доступна бесплатная и
            практически моментальная трассировка IP адреса или сайта. Она поможет вам выполнить
            сетевую диагностику, определяющую скорость послания запросов через серверы в интернете
            при открытии веб-ресурса в браузере. С помощью трассировки вы сумеете определить причины
            возможных проблем, связанных с медленной загрузкой сайта.
          </p>
        `,
        optionsLabel: "Показать маршрут пакета по странам:",
        seo: ""
      }
    },
    auth: {
      login: {
        heading: "Войти в личный кабинет",
        agreementPartOne: "Пользуясь сайтом, Вы соглашаетесь с",
        agreementPartSecond: "политикой конфиденциальности",
        forgot: "Забыли пароль?",
        recoverPassword: "Восстановить пароль",
        register: "Регистрация",
        login: "Войти"
      },
      register: {
        heading: "Регистрация"
      },
      forgot: {
        heading: "Восстановление пароля"
      }
    },
    common: {
      error: {
        title: "Упс... Что-то пошло не так.",
        description: `<p>Приносим извинения за неудобства, наши специалисты уже получили сообщение касательно этой ошибки!</p>
            <p>Не волнуйтесь, они сейчас быстро все подчинят :)</p>`
      }
    }
  },
  shared: {
    reviews: {
      heading: "Отзывы",
      button: "Посмотреть все отзывы",
      moreButton: "Показать больше",
      action: "Прочитать отзыв"
    },
    hasPromocode: "Есть промокод?",
    address: {
      title: "Где мы находимся"
    },
    contacts: {
      title: "Как связаться с нами"
    },
    socials: {
      title: "Наши социальные сети"
    },
    workingHours: {
      title: "Часы работы",
      aroundTheClock: "Круглосуточно",
      withoutWeekends: "Без выходных"
    },
    cabinet: "Личный кабинет",
    orderModal: {
      title: "Форма заказа",
      description:
        "IP адреса будут подключены автоматически и будут отображаться в личном кабинете после оплаты.",
      ipTooltip: "Только статический IP",
      ipValidationError: "Этот IP не может использоваться для авторизации"
    },
    faq: {
      heading: "Часто задаваемые вопросы",
      noData: "Вопросы не найдены"
    },
    tools: {
      yourIp: "Ваш IP:",
      yourAnonymity: "Ваша анонимность:",
      result: "Результат проверки:",
      yourGeo: "Ваша геопозиция:",
      warning: "<span>WebRTC включен</span> и может раскрыть ваш реальный IP-адрес!"
    },
    table: {
      options: {
        viewComment: {
          label: "Просмотреть",
          title: "Просмотр комментария",
          caption: "Комментарий",
          error: "Ошибка при загрузке комментария",
          empty: "Нет комментария к данному IP"
        },
        deleteComment: {
          label: "Удалить",
          title: "Удаление комментария",
          text: "Вы уверены, что хотите  удалить комментарий?",
          success: "Комментарий успешно удален",
          error: "Ошибка при удалении комментария"
        },
        addComment: {
          label: "Добавить",
          title: "Добавление комментария",
          editTitle: "Изменение комментария",
          success: "Комментарий успешно добавлен",
          error: "Ошибка добавления комментария"
        }
      },

      show: "Вывод"
    }
  },
  currencies: {
    rub: "руб.",
    usdUpper: "$"
  },
  form: {
    labels: {
      proxyType: "Тип прокси:",
      country: "Страна:",
      rentalPeriod: "Срок аренды:",
      proxyCount: "Количество:",
      proxyGoal: "Цель:",
      proxyService: "Услуга:",
      authType: "Способ авторизации:",
      paymentType: "Способ оплаты:",
      name: "Логин:",
      email: "Email:",
      oldEmail: "Старый email:",
      newEmail: "Новый email:",
      confirmNewEmail: "Подтвердите новый email:",
      password: "Пароль:",
      oldPassword: "Старый пароль:",
      newPassword: "Новый пароль:",
      confirmNewPassword: "Подтвердите новый пароль:",
      promocode: "Промокод:",
      selectAll: "Выбрать все",
      ipOrDomain: "Введите IP адрес или домен",
      testPingPackages: "Тестовые пакеты ping",
      verificationResult: "Результат проверки:",
      commentToIp: "Комментарий к IP:",
      proxyProtocol: "Протокол:",
      emailLinkSuccess: "Ссылка для восстановления пароля отправлена",
      mobileOperator: "Мобильный оператор:",
      modemRotationMin: "Обновление IP:",
      proxyList: "Список прокси:",
      nonWorking: "Нерабочий",
      domainsInIp: "Домены в IP"
    },
    placeholders: {
      promocode: "Введите промокод",
      noOptions: "Нет вариантов",
      firstSelectCountry: "Выберите страну",
      firstSelectMobileOperator: "Выберите мобильного оператора",
      yourEmail: "Ваш e-mail",
      enterPassword: "Введите пароль",
      enterEmail: "Введите e-mail",
      newPassword: "Новый пароль",
      confirmNewPassword: "Подтвердите новый пароль",
      selectPaymentMethod: "Выберите способ оплаты",
      paymentMethod: "Способ оплаты",
      enterCustomGoal: "Цель использования",
      selectOperator: "Выберите оператора",
      selectRentPeriod: "Выберите срок аренды",
      selectRotation: "Выберите обновление ip",
      selectProtocol: "Выберите протокол",
      personalExportPattern: "Персональный формат выгрузки",
      yourName: "Ваше имя",
      companyName: "Название компании",
      emailOrOther: "E-mail или другое"
    },
    actions: {
      login: "Войти",
      register: "Зарегистрироваться",
      buyProxy: "Купить прокси",
      makeOrder: "Сделать заказ",
      sendMessage: "Отправить сообщение",
      changePassword: "Изменить пароль",
      changeEmail: "Изменить email",
      applyChange: "Применить изменение",
      extend: "Продлить",
      startScan: "Начать сканирование",
      cancel: "Отмена",
      delete: "Удалить",
      add: "Добавить",
      restore: "Восстановить",
      create: "Создать",
      edit: "Изменить",
      goToMainPage: "Вернуться на главную",
      submit: "Отправить",
      generateKey: "Сгенерировать ключ"
    },
    rules: {
      name: {
        required: "Введите логин",
        invalid: "Неправильный логин"
      },
      email: {
        required: "Почта обязательна",
        invalid: "Некорректная почта",
        match: "Почты не совпадают"
      },
      password: {
        required: "Пароль обязателен",
        min: "Минимум символов",
        match: "Пароли не совпадают",
        notAllowed: "Пароли содержат недопустимые символы",
        invalid: "Неправильный пароль",
        passwordContainsSpaces: "Пароль содержит пробелы"
      },
      apiKey: {
        required: "Требуется API-ключ"
      },
      accessIpAddresses: {
        invalid: "Неверный IP-адрес",
        maxCount: "Вы можете указать до 3 IP-адресов"
      },
      ipOrDomain: {
        required: "Требуется IP или домен",
        invalid: "Неверный IP или домен"
      },
      proxyList: {
        required: "Требуется список прокси",
        invalid: "Неверный список прокси",
        copiesFound: "Найдены копии"
      },
      quantity: {
        minQuantity: "Минимальное количество {{quantity}}"
      },
      personalExportPattern: {
        required: "Персональный формат выгрузки обязателен",
        requiredVariables: "Обов'язкові змінні",
        atLeastOneVariableMustBeUsed: "Должна использоваться хотя бы одна переменная",
        invalidVariables: "Недопустимые переменные"
      },
      common: {
        hasUrl: "Поле содержит URL-адрес",
        required: "Поле обязательно",
        min: "Поле должно содержать не менее {{min}} символов",
        max: "Поле не должно содержать больше {{max}} символов",
        oneOf: "Поле должно иметь одно из следующих значений"
      }
    },
    serverErrors: {
      balance: "Недостаточно средств",
      promocode: "Промокод недействителен",
      default: "Произошла ошибка",
      minQuantityTen: "Минимальное количество для заказа 10",
      ips: "Невозможно продлить прокси с истечённым сроком действия",
      minPrice: "Минимальная цена для платежной системы {{price}}",
      minAmountForPaymentSystem: "Минимальная сумма для платежной системы {{amount}}",
      incorrectAmount: "Неправильная сумма",
      incorrectPaymentSystem: "Неправильная платежная система",
      incorrectLanguageCode: "Неправильный код языка",
      incorrectOldPassword: "Старый пароль неверен",
      incorrectNewPassword: "Новый пароль некорректен",
      incorrectGoal: "Неправильная цель использования",
      passwordEquals: "Новый пароль должен отличаться от старого",
      incorrectEmail: "Пожалуйста введите существующий емейл"
    }
  },
  other: {
    piece: "IP",
    ips: "IPs",
    pieces: "штук",
    from: "От",
    discount: "Скидка:",
    price: "Стоимость:",
    totalSum: "Общая сумма:",
    countPiece: "Количество:",
    selected: "Выбрано:",
    proxy: "прокси.",
    logout: "Выход",
    showAll: "Показать все",
    byLink: "По ссылке",
    insertList: "Вставить списком",
    insertListDescription: "Напишите перечень IP, которые хотите продлить",
    apply: "Применить",
    cancel: "Отменить",
    noMatchesIp: "Совпадений не найдено",
    foundIp: "Найдено {{count}} IP",
    foundIpFrom: "Найдено {{endCount}} из {{startCount}} введённых IP"
  },
  export: {
    ownFormat: "Собственный формат",
    txt: "txt",
    excel: "excel",
    window: "В новом окне"
  },
  days: "дней",
  copyMessageSuccess: "Успешно скопировано",
  copyMessageError: "Не удалось скопировать",
  passwordChangeSuccess: "Пароль успешно изменён",
  loginUnauthorized: "Неправильные данные для входа",
  serverError: "Ошибка сервера",
  userNotFound: "Пользователь не найден",
  userExist: "Пользователь с таким адресом уже зарегистрирован",
  registerSuccess: "Регистрация звершена",
  orderCreateError: "Ошибка создания заказа. Попробуйте еще раз",
  ipAddressesSaved: "IP-адреса сохранены",
  repayError: "Произошла ошибка при обработке платежа",
  order: {
    messages: {
      success: {
        title: "Заказ оформлен",
        description: "Вы успешно оформили заказ, а тут будет информация на вторую строку."
      },
      error: {
        title: "Сбой покупки",
        description: "Что-то пошло не так, по независящим от нас причинам заказ не был оформлен."
      }
    }
  },
  common: {
    goBack: "Вернуться назад",
    tryAgain: "Попробовать снова",
    iAgree: "Я соглашаюсь",
    toTheTerms: "с условиями",
    notUsed: "Не используется",
    used: "Используется",
    absent: "Отсутствует",
    noData: "Нет данных",
    active: "Активный",
    notActive: "Не активний",
    on: "Включено",
    off: "Выключено",
    cancel: "Отмена",
    canceled: "Отменено",
    timeout: "Время вышло",
    potentialLeak: "Возможная утечка",
    leakageThreat: "Угроза утечки",
    potentialThreat: "Потенциальная угроза",
    threatsAreAbsent: "Угрозы отсутствуют",
    browser: "Браузер",
    yes: "Да",
    no: "Нет",
    detected: "Обнаружено",
    notDetected: "Не обнаружено",
    copy: "Копировать",
    millisecondShort: "мс",
    shown: "Показано",
    postscriptShort: "P.S.",
    read: "Читать",
    order: "Заказать",
    quantity: "Количество",
    export: "Экспорт",
    example: "Пример",
    availableVariables: "Доступные переменные",
    byLink: "По ссылке",
    byTime: "По времени",
    rotation: "Ротация",
    contactUs: "Связаться с нами",
    enterprise: "Предприятие",
    other: "Другое",
    email: "Email",
    maxCharacters: "Макс. {{max}} символов"
  },
  discountOfferTooltip: {
    addButton: "Добавьте",
    discountOfferText: "{{count}} IP, это дешевле на {{discountInPercent}}%"
  },
  messages: {
    logInWithYourPreviousCredentials: "Войдите в систему, используя свои предыдущие учетные данные",
    linkToTheChangeHasBeenSentByEmail: "Ссылка на изменение была отправлена на эмейл",
    emailAlreadyInUse: "E-mail уже используется",
    emailChanged: "E-mail изменен",
    noUserFoundWithThisEmail: "Пользователь с этой электронной почтой не найден",
    userNotFoundByHash: "Пользователь не найден по хэшу",
    newEmailEqualsUserEmail:
      "Новый адрес электронной почты соответствует адресу электронной почты пользователя",
    templateSaved: "Шаблон сохранен",
    failedToSaveTemplate: "Не удалось сохранить шаблон",
    failedToLoadTemplate: "Не удалось загрузить шаблон",
    captchaFailed: "Не удалось завершить проверку CAPTCHA",
    temporaryEmails: "Извините, использование времнных эмейлов невозможно. ",
    pleaseResubmitTheForm: "Пожалуйста, повторите отправку формы",

    promoCodeNotExist: "Промокод не существует",
    promoCodeNotActive: "Промокод не активен",
    promoCodeInvalid: "Промокод недействителен",
    promoCodeConditionInvalid: "Промокод не соответствует условиям"
  }
};
