import { createSlice } from "@reduxjs/toolkit";

import {
  checkIp,
  getAnonymityPercent,
  getClientBlackListsCheck,
  getClientDns,
  getFingerprint
} from "./tools.thunks";

const initialState = {
  isToolsActive: true,
  userIp: null,
  // isToolsActive: process.env.REACT_APP_MODE === "dev",
  clientIpInfo: {
    data: null,
    errors: null,
    loading: false
  },
  clientAnonymityPercent: {
    data: null,
    errors: null,
    loading: false
  },
  clientFingerprintInfo: {
    data: null,
    errors: null,
    loading: false
  },
  clientDns: {
    data: null,
    errors: null,
    loading: false
  },
  clientBlackListsCheck: {
    data: null,
    errors: null,
    loading: false
  }
};

const ToolsSlice = createSlice({
  name: "tools",
  initialState,
  reducers: {
    setToolsActive(state, action) {
      state.isToolsActive = action.payload;
    },
    setUserIp: (state, action) => {
      state.userIp = action.payload;
    }
  },
  extraReducers: {
    // clientIpInfo
    [checkIp.pending]: (state) => {
      state.clientIpInfo.loading = true;
    },
    [checkIp.rejected]: (state, action) => {
      state.clientIpInfo.loading = false;
      state.clientIpInfo.errors = action.payload;
    },
    [checkIp.fulfilled]: (state, action) => {
      state.clientIpInfo.loading = false;
      state.clientIpInfo.data = action.payload;
    },
    // clientShortInfo
    [getAnonymityPercent.pending]: (state) => {
      state.clientAnonymityPercent.loading = true;
    },
    [getAnonymityPercent.rejected]: (state, action) => {
      state.clientAnonymityPercent.loading = false;
      state.clientAnonymityPercent.errors = action.payload;
    },
    [getAnonymityPercent.fulfilled]: (state, action) => {
      state.clientAnonymityPercent.loading = false;
      state.clientAnonymityPercent.data = action.payload;
    },
    // getFingerprint
    [getFingerprint.pending]: (state) => {
      state.clientFingerprintInfo.loading = true;
    },
    [getFingerprint.rejected]: (state, action) => {
      state.clientFingerprintInfo.loading = false;
      state.clientFingerprintInfo.errors = action.payload;
    },
    [getFingerprint.fulfilled]: (state, action) => {
      state.clientFingerprintInfo.loading = false;
      state.clientFingerprintInfo.data = action.payload;
    },
    // getClientDns
    [getClientDns.pending]: (state) => {
      state.clientDns.loading = true;
    },
    [getClientDns.rejected]: (state, action) => {
      state.clientDns.loading = false;
      state.clientDns.errors = action.payload;
    },
    [getClientDns.fulfilled]: (state, action) => {
      state.clientDns.loading = false;
      state.clientDns.data = action.payload;
    },
    // getClientBlackListsCheck
    [getClientBlackListsCheck.pending]: (state) => {
      state.clientBlackListsCheck.loading = true;
    },
    [getClientBlackListsCheck.rejected]: (state, action) => {
      state.clientBlackListsCheck.loading = false;
      state.clientBlackListsCheck.errors = action.payload;
    },
    [getClientBlackListsCheck.fulfilled]: (state, action) => {
      state.clientBlackListsCheck.loading = false;

      state.clientBlackListsCheck.data = Object.entries(action.payload).reduce(
        (acc, [host, status]) => {
          return [...acc, ...(status ? [host] : [])];
        },
        []
      );
    }
  }
});

export const ToolsActions = {
  ...ToolsSlice.actions,
  checkIp: checkIp,
  getAnonymityPercent: getAnonymityPercent,
  getFingerprint: getFingerprint,
  getClientDns: getClientDns,
  getClientBlackListsCheck: getClientBlackListsCheck
};

export default ToolsSlice.reducer;
