import { useEffect } from "react";

import { useSelector } from "react-redux";

import { useActions } from "./hooks";
import { ApiService, UserService } from "./services";
import {
  getProxyState,
  getReviewState,
  getSeoContentLoadedState,
  getSeoDefaultLoadedState,
  getToolsUserIp,
  isCaptchaLoadedState
} from "./store/selectors";
import { collectDefaultContent } from "./utils/helpers/serverSideState.helpers";

export const App = ({ routerProvider: RouterProvider, routerProps }) => {
  const seoDefaultLoaded = useSelector(getSeoDefaultLoadedState);
  const seoContentLoaded = useSelector(getSeoContentLoadedState);
  const { reviewsPreviewLength } = useSelector(getReviewState);
  const { contentLoaded: proxyContentLoaded } = useSelector(getProxyState);
  const isCaptchaLoaded = useSelector(isCaptchaLoadedState);
  const userIp = useSelector(getToolsUserIp);

  const {
    setProxies,
    setReviews,
    setPeriods,
    fetchFingerPrintOpensourse,
    setSeoDefault,
    setUser,
    setSeoContent,
    setToolsActive,
    fetchCaptchaInfo
  } = useActions();

  useEffect(() => {
    (async () => {
      // Get user from storage
      const user = JSON.parse(localStorage.getItem("user"));

      if (user) {
        setUser(user);
      }

      let visitorId = "";
      // Get fingerprint
      try {
        const fingerprintResult = await fetchFingerPrintOpensourse();
        visitorId = fingerprintResult?.payload?.visitorId ?? "";
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
      // Check is user blocked
      if (visitorId !== "") {
        try {
          const payload = { fingerprint: visitorId };
          const isBlockedResult = await UserService.isBlocked(payload);

          if (isBlockedResult?.data) {
            ApiService.blockedPaymentSystemHistory(payload);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Collect default content
  useEffect(() => {
    if (!proxyContentLoaded) {
      ApiService.getContent({ sizeOfReviews: reviewsPreviewLength + 1 })
        .then((response) => {
          if (response.data) {
            const {
              proxies,
              reviews,
              rentPeriods,
              // goalGroups
              toolsClientApiEnable
            } = response.data;

            if (toolsClientApiEnable) {
              setToolsActive(toolsClientApiEnable);
            }

            if (Object.keys(proxies).length) {
              setProxies(proxies);
            }

            setReviews(reviews);
            setPeriods(rentPeriods);
            // setGroups(goalGroups);
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyContentLoaded]);
  useEffect(() => {
    if (!seoDefaultLoaded) {
      ApiService.getSeoDefault()
        .then((response) => {
          if (response.data) {
            setSeoDefault({
              title: response.data.default_seo_title,
              description: response.data.default_seo_description
            });
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seoDefaultLoaded]);
  useEffect(() => {
    if (!seoContentLoaded) {
      ApiService.getSeoContent()
        .then((response) => {
          if (response.data) {
            setSeoContent(response.data);
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seoContentLoaded]);
  // Collect Captcha
  useEffect(() => {
    if (!isCaptchaLoaded) {
      fetchCaptchaInfo(userIp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCaptchaLoaded]);

  // console.count("App");

  return <RouterProvider {...routerProps} />;
};

App.getServerSideState = async (store) => {
  await collectDefaultContent(store);
};
