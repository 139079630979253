import { ApiService } from "../../services";
import { StoreActions } from "../../store/actions";
import { fetchCaptchaInfo } from "../../store/captcha/captcha.thunks";
import { getPaymentOptions } from "../../store/payment/payment.thunks";
import { getProxyState } from "../../store/proxy/proxy.selectors";
import { getToolsUserIp } from "../../store/selectors";
import { fetchPageSeo } from "../../store/seo/seo.thunks";
import { getTranslationDefaultLocaleState } from "../../store/translation/translations.selectors";

import { isSSR } from "./isSSR";

export const collectDefaultContent = async (store) => {
  const state = store.getState();
  const userIp = getToolsUserIp(state);
  await store.dispatch(fetchCaptchaInfo(userIp));

  await store.dispatch(getPaymentOptions({ email: "", fingerprint: "" }));

  await Promise.all([
    ApiService.getContent({ sizeOfReviews: state.review.reviewsPreviewLength + 1 }),
    ApiService.getSeoDefault(),
    ApiService.getSeoContent()
  ])
    .then(async (responses) => {
      const [content, seoDefault, seoContent] = responses;

      if (content.data) {
        const {
          proxies,
          reviews,
          rentPeriods
          // goalGroups
        } = content.data;
        store.dispatch(StoreActions.setProxies(proxies));
        store.dispatch(StoreActions.setReviews(reviews));
        store.dispatch(StoreActions.setPeriods(rentPeriods));
        // store.dispatch(StoreActions.setGroups(goalGroups));
      }

      if (seoDefault.data) {
        store.dispatch(
          StoreActions.setSeoDefault({
            title: seoDefault.data.default_seo_title,
            description: seoDefault.data.default_seo_description
          })
        );
      }

      if (seoContent.data) {
        store.dispatch(StoreActions.setSeoContent(seoContent.data));
      }
    })
    .catch(() => {
      console.log("error collecting state!!!");
    });
};

// SEO
export function prepareSeoRequestParams(state, tag, languageCode, params, tagPostfix, location) {
  let requestParams;

  if (tag === "article") {
    requestParams = {
      article: params?.tag
    };
  }

  if (tag === "countries") {
    const { proxies } = getProxyState(state);

    const proxy_type = "IPv4";
    const proxy = proxies[proxy_type.toUpperCase()];
    const country = proxy?.countries.find((el) => el.urlParam === params?.proxyCountry)?.urlParam;

    if (!proxy || !country) {
      return;
    }

    requestParams = {
      proxy_type,
      country
    };
  }

  if (tag === "ipv6-proxy" || tag === "isp-proxy") {
    const { proxies } = getProxyState(state);

    const proxy = proxies[params?.proxyType.toUpperCase()];

    if (!proxy) {
      return;
    }

    requestParams = {
      proxy_type: proxy?.proxyTypeName
    };
  }

  return {
    tag,
    location: location ? location : "/",
    tagPostfix,
    languageCode,
    params: requestParams
  };
}
export const collectPageSeoForSsr = async (store, params) => {
  if (!params?.tag) return;

  const state = store.getState();

  const languageCode = params?.locale ?? getTranslationDefaultLocaleState(state);
  const tag = params?.tag;
  let tagPostfix = "";

  if (tag === "article") {
    tagPostfix = `/${params?.params?.tag}`;
  }

  if (tag === "countries") {
    tagPostfix = `/IPV4/${params?.params?.proxyCountry}`;
  }

  if (tag === "ipv6-proxy" || tag === "isp-proxy") {
    const proxyType = params?.location.split("/")[1]?.toUpperCase();
    tagPostfix = `/${proxyType}`;

    params = {
      ...params,
      params: {
        ...params.params,
        proxyType
      }
    };
  }

  const tagWithPostfix = tag + tagPostfix;

  const seoPageParams = {
    tag: tagWithPostfix,
    languageCode
  };

  if (isSSR()) {
    await store.dispatch(
      StoreActions.setSeoPageParams({
        tag: tagWithPostfix,
        languageCode
      })
    );
  }

  if (
    state.seo.contentLoaded &&
    (!state.seo.data[languageCode] || !state.seo.data[languageCode][tagWithPostfix]) &&
    (state.seo.loadedPagesTagsByLocale[languageCode] === undefined ||
      state.seo.loadedPagesTagsByLocale[languageCode]?.indexOf(tagWithPostfix) === -1)
  ) {
    const requestParams = prepareSeoRequestParams(
      state,
      params?.tag,
      params?.locale,
      params?.params,
      tagPostfix,
      params?.location
    );

    requestParams && (await store.dispatch(fetchPageSeo(requestParams)));
  }

  return seoPageParams;
};
