export const translationsEn = {
  header: {
    cabinet: "Personal account",
    logIn: "Log-in"
  },
  menu: {
    buyProxy: "Buy Proxy",
    proxyFor: "Proxy for",
    prices: "Prices",
    company: "Company",
    instruments: "Instruments"
  },
  notifications: {
    title: "Notifications",
    clear: "Clear all",
    new: "NEW",
    expand: "Expand",
    collapse: "Collapse",
    noNotifications: "No notifications"
  },
  company: {
    titles: {
      aboutUs: "About us",
      information: "Information",
      other: "Other"
    },
    links: {
      conditions: "Conditions",
      faq: "FAQ",
      contacts: "Contacts",
      reviews: "Reviews",
      promocodes: "Promocodes",
      blog: "Blog",
      cabinet: "Cabinet",
      offer: "Public offer",
      policy: "Private policy",
      refundPolicy: "Refund policy",
      cookiePolicy: "Cookie policy",
      documentation: "Documentation"
    }
  },
  tools: {
    myIp: { label: "My IP" },
    pingIp: { label: "Ping IP" },
    checkEvercookie: { label: "Check Evercookie" },
    proxyChecker: { label: "Proxy Checker" },
    myAnonymity: { label: "My anonymity" },
    checkWebrtc: { label: "WebRTC check" },
    portScanner: { label: "Port Scanner" },
    fingerprintScanner: { label: "Fingerprint Scanner" },
    ipTracing: { label: "IP Tracing" }
  },
  cabinet: {
    profile: { label: "Profile" },
    orders: {
      label: "Orders",
      sub: {
        ordersIpv4: "IPv4",
        ordersIpv6: "IPv6",
        ordersIsp: "ISP",
        ordersMobileProxy: "Mobile Proxy",
        ordersNotActive: "Not active sections"
      },
      status: {
        active: "active"
      },
      noData: "No orders data",
      extendError: "An error occurred when extending the proxies"
    },
    newOrder: { label: "New order" },
    balance: {
      label: "Balance",
      noData: "No balance history"
    },
    paymentHistory: {
      label: "Payment history",
      success: "Success",
      notSuccess: "Failed",
      noData: "No payment data"
    },
    actionsHistory: {
      label: "Action History",
      noData: "No actions history"
    },
    promocodes: {
      label: "Promo codes",
      noData: "No promocodes found"
    },
    subscription: { label: "Subscription" },
    faq: { label: "FAQ" },
    developersApi: { label: "API" },
    logout: { label: "Logout" }
  },
  languages: {
    ru: "Rus",
    en: "Eng",
    uk: "Ukr"
  },
  countries: {
    all: "All",
    RUS: "Russia",
    USA: "USA",
    UKR: "Ukraine",
    KAZ: "Kazakhstan",
    GBR: "England",
    DEU: "Germany",
    BGR: "Bulgaria",
    FRA: "France",
    NLD: "Netherlands",
    BLR: "Belarus",
    MDA: "Moldova",
    LVA: "Latvia",
    IND: "India",
    POL: "Poland",
    ITA: "Italy",
    LKA: "Sri Lanka",
    AUT: "Austria",
    AUS: "Australia",
    JPN: "Japan",
    THA: "Thailand",
    CAN: "Canada",
    MEX: "Mexico",
    ESP: "Spain",
    GEO: "Georgia",
    EGY: "Egypt"
  },
  authMethods: {
    ip: "Static IP",
    login: "Username & Password"
  },
  proxySocials: {
    vk: "Vkontakte",
    classmates: "Classmates",
    telegram: "Telegram",
    linkedin: "LinkedIn",
    facebook: "Facebook",
    instagram: "Instagram",
    twitter: "Twitter",
    youtube: "YouTube",
    periscope: "Periscope"
  },
  contacts: {
    skype: "YouProxy",
    telegram: "Telegram",
    vk: "Vkontakte",
    telegramChannel: "Telegram Channel",
    facebook: "Facebook",
    facebookGroup: "Facebook Group",
    mail: "support@youproxy.io",
    fields: {
      name: "Your name",
      email: "Your email",
      text: "Your message"
    },
    validateErrors: {
      name: {
        required: "Name is required"
      },

      email: {
        required: "Email is required",
        invalid: "Invalid email"
      },

      text: {
        required: "Message is required"
      }
    },
    serverSuccess: {
      title: "Message sent",
      description: "In the near future, our manager will answer all your questions.",
      action: "Go back"
    },
    serverError: {
      title: "Message was not sent",
      description:
        "Something went wrong; for reasons beyond our control, the letter was not delivered.",
      action: "Go back"
    },
    serverNotRegistered: {
      title: "This email is not registered",
      description: "If you want to send message from this email, you need to register first.",
      action: "Go back"
    },
    serverNotValidMail: {
      title: "Please enter a valid email",
      description: "Message was not sent because entered email does not exist.",
      action: "Go back"
    }
  },
  payments: {
    usd: "$",
    eur: "EUR",
    mbtc: "mBTC",
    uah: "UAN",
    rub: "RUB"
  },
  buyProxy: {
    ipv4: "IPv4",
    ipv6: "IPv6",
    isp: "ISP",
    mobile: "Mobile Proxy"
  },
  proxyFor: {
    socialNetworks: "Social Network",
    webScraping: "Page scraping",
    gamingProxies: "Online games",
    sneaker: "Proxy sneaker",
    onlineMarketplace: "Online Market",
    other: "Other",
    searchEngineParsing: "Search engine parsing"
  },
  mobileProxy: {
    beeline: "Beeline",
    vodaphone: "Vodafone",
    kievstar: "Kyivstar",
    life: "Life",
    mts: "MTS"
  },
  faq: {
    generalQuestions: "Common questions",
    extendProxy: "Proxy extension",
    technicalQuestions: "Tech. questions",
    security: "Security",
    authorizationSettings: "Authorization/Setting"
  },
  advantages: {
    countryOwnership: "Check IPv4, IPv6 for country ownership;",
    protocolDefinition: "Definition of HTTP/S, SOCKS5 protocol type and current speed;",
    anonymousChecking: "Checking address anonymity;",
    proxyChecking: "Group, high-speed multi-threaded proxy checking in 64 threads;",
    downloadUpload: "Ability to upload the list and upload the result of the check;",
    resultsFilter: "Easy displaying results with a display filter."
  },
  portType: {
    popularPorts: "Popular ports",
    hiddenPorts: "Hidden Ports",
    proxyPorts: "Proxy Ports"
  },
  answers: {
    yes: "Yes",
    no: "No"
  },
  proxyGoal: {
    goal1: "Proxy target",
    goal2: "Proxy target 2",
    goal3: "Proxy target 3"
  },
  proxyServices: {
    service1: "Proxy service",
    service2: "Proxy service 2",
    service3: "Proxy service 3"
  },
  proxyRentalPeriod: {
    day: "Days"
  },
  ipType: {
    all: "All",
    https: "HTTPS",
    socks5: "SOCKS5",
    http: "HTTP"
  },
  pages: {
    app: {
      home: {
        hero: {
          heading: "Buy high speed private <span>proxy</span>",
          totalSum: "The cost is: "
        },
        geos: {
          heading: "Buy a proxy for the desired geo"
        },
        features: {
          heading: "Features",
          description:
            "From Youproxy you buy exclusively private proxy servers with support for both protocols: Socks 5 and HTTP(s). Also, we list the main characteristics of our service below.",
          leftItems: {
            security: {
              label: "Protection",
              value: "100%"
            },
            uptime: {
              label: "uptime",
              value: "99%"
            },
            delivery: {
              label: "Issue",
              value: "3 min"
            },
            support: {
              label: "Support",
              value: "24/7"
            }
          },
          rightItems: {
            coverage:
              "You can buy proxies in <span>20+ countries</span>, the pool of which is constantly updated and expands",
            speed:
              "One of the main advantages of our proxies: <span>minimum ping</span> and <span>high speed</span>",
            networks:
              "Purchased proxies are issued with a spread of <span>by networks</span> and <span>subnets</span>",
            fullPersonal: "100% <span>personal</span> proxies are offered for purchase"
          },
          downloadListCard: {
            title: "Download list (TXT)",
            description:
              "Enjoy using a modern control panel once you download the proxy list. Every proxy plan offers a handful of valuable and useful",
            button: {
              text: "Explore"
            }
          },
          apiCard: {
            title: "API",
            description:
              "Optimize your workflow and budget by integrating with Webshare RESTful APIs. Webshare offers the most robust proxy APIs.",
            button: {
              text: "View API"
            }
          }
        },
        form: {
          minimal: "Minimal proxy count is "
        },
        seo: ""
      },
      proxyFor: {
        hero: {
          heading: "Proxy for",
          caption: "Proxies by the piece:"
        },
        packet: {
          heading: "Batch proxies",
          table: {
            goals: "Goals:",
            countPrice: "Quantity/Cost:",
            actions: {
              order: "Order"
            }
          }
        },
        seo: ""
      },
      proxyByCountry: {
        prices: {
          title: "Batch proxies",
          description:
            "{{country}} proxies are issued exclusively to one person with support for both protocols: Socks5 and HTTP/A7 In piece form, you can purchase any quantity, taking into account all the discounts provided for the quantity and rental period"
        },
        whatYouGet: {
          title: "What you get",
          content: [
            "API Authorization",
            "Upload in any format",
            "Stable UP'time",
            "High trust indicator",
            "Variety of networks/subnets"
          ]
        },
        apiIntegration: {
          title: "Integrate API",
          description:
            "{{country}} proxies are issued exclusively to one person with support for both protocols: Socks5 and HTTP/A7 In piece form, you can purchase any quantity, taking into account all the discounts provided for the quantity and rental period"
        }
      },
      buyProxy: {
        hero: {
          heading: "Proxy",
          caption: "Proxies by the piece:",
          description:
            "{{countryName}} proxies are issued exclusively in one hand with support for both protocols: Socks5 and HTTP / A7 In piece form, you can buy any quantity, taking into account all the discounts for the quantity and rental period",
          adv: {
            server: "<span>HTTP(S)</span> / SOCKS5",
            atom: "<span>170+</span> subnetworks",
            support: "<span>Support</span> <br /> 24/7/365"
          },
          animationLabels: {
            noLimits: "No limits",
            support: "24/7 support",
            connection: "Stable connection",
            anonymity: "Anonymity"
          }
        },
        packet: {
          heading: "Batch proxies:",
          table: {
            goals: "Goals:",
            countPrice: "Quantity/Cost:",
            actions: {
              order: "Order"
            },
            searchLabel: "Proxy for {{proxyFor}} by GEO:"
          }
        }
      },
      mobileProxy: {
        hero: {
          heading: "Mobile proxies",
          description:
            "Dynamic mobile proxies - seamless surfing for any complexity, without blocks or CAPTCHA. IP addresses from real GSM carriers ensure maximum anonymity and security levels.",
          caption: "MOBILE LTE PROXY PRICES:",
          support: "Online support",
          order: {
            info: {
              characteristics: "Characteristics:",
              country: "Country:",
              proxyCount: "Proxy count:",
              dedicatedIp: "Dedicated IP:",
              multipleSubnets: "Multiple Subnets:",
              note: {
                label: "Note:",
                content: ""
              }
            },
            result: {
              title: "Order options",
              proxy: "Proxy:",
              rentalPeriod: "Period:"
            }
          }
        },
        seo: ""
      },
      blog: {
        heading: "Blog",
        noData: "No blog posts found"
      },
      article: {
        sidebar: {
          new: "New",
          popular: "Popular"
        },
        noData: "Sorry, article not found"
      },
      notFound: {
        title: "Oops... There doesn't seem to be such a page.",
        text: "The page may have been removed or is temporarily unavailable. Please contact our managers if you need any assistance.",
        btn: "Go back to the main page"
      },
      reviews: {
        hero: {
          heading: "Reviews",
          sources: "Sources",
          showAll: "Show all reviews",
          allReviews: "All reviews"
        },
        noData: "No reviews found"
      },
      promocodes: {
        heading: "Promocodes",
        more: "Show more",
        link: "Use promocode",
        validDate: "Valid until",
        used: "Used",
        times: "times",
        noData: "Sorry, no promocodes found"
      },
      contacts: {
        heading: "Contacts",
        form: "Feedback form",
        description:
          "We are happy to receive feedback, suggestions and questions from you regarding our service. Our manager will review your application and give an answer as soon as possible."
      },
      thanks: {
        hero: {
          heading: "Thanks for buying!",
          subHeading: "Payment was successful. Thank you for choosing our service!",
          info: "We have accepted your order and our system connects IP addresses. Most IP addresses are available in 5-10 minutes. Registration is automatic, after purchasing a proxy - the data is sent to the mail specified in the order and displayed in the personal account.",
          description: `
          <p>
            All information about your order, you can see in the personal account. The same
            track order status, IP expiration time and manage transactions.
          </p>
          <p>
            If you have problems with the proxy connection, contact the operator for getting
            help.
          </p>`
        },
        replenishmentHero: {
          heading: {
            success: "Thanks for payment!",
            pending: "Your payment is in process!",
            cancel: "Your payment has been canceled"
          },
          subHeading: "Amount to be added: {amount}",
          info: {
            success:
              "We have successfully accepted your payment. Your new balance will be available in 5-10 minutes.",
            pending:
              "Your payment is still in process. Please wait until new balance will appear in your personal account",
            cancel:
              "Seems like you payment has been canceled. Please check your bank account or payment wallet and try again."
          },
          description: `
            <p>
              All information about your balance, you can see in the personal account.
            </p>
            <p>
              If you have problems with your balance, contact the operator for getting
              help.
            </p>
          `
        },
        contacts: {
          heading: "We will be grateful for your opinion",
          description:
            "Leave feedback on any of the resources about our work, indicate the pros and cons of working with us. We take into account every opinion, correct mistakes to get better for you!"
        }
      },
      balanceThanks: {
        hero: {
          success: {
            heading: "Your balance is replenished!",
            subHeading: "The amount added to your balance is: {amount}$",
            info: "",
            description: `
              <p>
                All information about your Balance, you can see in the personal account.
                Also you can see the history of your balance replenishments.
              </p>
              <p>
                If you have problems with your balance, contact the operator for getting
                help.
              </p>
            `
          },

          cancel: {
            heading: "Your payment has been cancelled!",
            subHeading: "There may be a problem with your bank, please try again",
            info: "",
            description: `
              <p>
                All information about your Balance, you can see in the personal account.
                Also you can see the history of your balance replenishments.
              </p>
              <p>
                If you have problems with your balance, contact the operator for getting
                help.
              </p>
            `
          },

          pending: {
            heading: "Your payment is being proceeded!",
            subHeading: "It will take some time for your new balance to appear",
            info: "",
            description: `
              <p>
                All information about your Balance, you can see in the personal account.
                Also you can see the history of your balance replenishments.
              </p>
              <p>
                If you have problems with your balance, contact the operator for getting
                help.
              </p>
            `
          }
        }
      },
      price: {
        heading: "Proxy Prices",
        location: "Location"
      },
      offer: {
        heading: "",
        content: ""
      },
      conditions: {
        heading: "",
        content: ""
      },
      policy: {
        heading: "",
        content: ""
      },
      refundPolicy: {
        heading: "",
        content: ""
      },
      cookiePolicy: {
        heading: "",
        content: ""
      }
    },
    cabinet: {
      profile: {
        heading: "Profile"
      },
      orders: {
        tags: {
          ipv4: {
            heading: "Proxy IPv4"
          },
          ipv6: {
            heading: "Proxy IPv6"
          },
          isp: {
            heading: "Proxy ISP"
          },
          "mobile-proxy": {
            heading: "Mobile proxy"
          },
          "inactive-partitions": {
            heading: "Orders"
          }
        },
        widgets: {
          system: {
            title: "Active orders:",
            rowLabel: "Proxy"
          },
          export: {
            title: "Export:",
            chooseFormat: "Choose Format:",
            downloadList: "Download List",
            selectProxies: "Please, select proxies for export",
            selectSOCKS: "Please select a proxy with SOCKS5 protocol",
            selectHTTP: "Please select a proxy with HTTPS protocol"
          },
          autoRenewal: {
            title: "Auto-renewal",
            description: "Select IPs from the list to enable or disable auto-renewal.",
            selectedIp: "Selected IP",
            enable: "Enable",
            disable: "Disable",
            selectIpsWithSameSettings: "Select IPs with the same settings",
            willCharged: "will be charged",
            alert: {
              priceError: "Error in cost calculation, please choose another IP.",
              notEnoughMoney: "The balance amount is insufficient to use the option. Please",
              topUpBalance: "top up your balance by",

              enabled: "Auto-renewal enabled",
              notEnabled: "Failed to enable auto-renewal",
              disabled: "Auto-renewal disabled",
              notDisabled: "Failed to disable auto-renewal"
            }
          },
          autoRenewalModal: {
            title: "Enable auto-renewal",
            descriptionEnableAR:
              "Select the period for which the selected IP addresses will be renewed and complete the procedure for enabling the option.",
            term: "Term",
            paymentMethod: "Payment method",
            confirm: "Confirm",
            cancel: "Cancel",
            disableAR: "Disable auto-renewal for IP",
            descriptionDisableAR:
              "Proxies will be active until the end of the term and will not be renewed for the next period"
          },
          exportModal: {
            description: "Please use the template to upload your proxies."
          }
        },
        table: {
          issueDate: "Issue date",
          expirationDate: "Expiration date",
          ip: "IP",
          activeFrom: "Start date",
          activeBy: "End date",
          ipStatus: "IP Status",
          reboot: "Rotation",
          everyTimeMinute: "every {{time}} min.",
          goal: "Goal",
          protocol: "Protocol",
          port: "Port",
          portSocks: "Socks port",
          portProxy: "Proxy port",
          status: "IP status",
          ACTIVE: "Active"
        }
      },
      newOrder: {
        heading: "New order"
      },
      balance: {
        heading: "Your balance",
        form: {
          heading: "Balance replenishment",
          inputLabel: "Top-up amount",
          inputPlaceholder: "Enter sum",
          selectLabel: "Payment methods",
          warning: "Some payment methods may charge an additional fee when paying",
          btn: "Continue"
        },
        table: {
          orderDate: "Date",
          orderSum: "Sum",
          orderStatus: "Status",
          payAction: "Pay",
          noData: "No transactions found"
        },
        options: {
          fillBalance: "To fill your balance",
          historyTransactions: "Transactions history"
        },
        status: {
          success: "Success",
          failed: "Failed"
        }
      },
      paymentHistory: {
        heading: "Payment history",
        table: {
          orderCompound: "Order compound:",
          amountCurrency: "Amount/Currency:",
          status: "Status:",
          buy: "Buy"
        },
        paymentActions: {
          ORDER_BUY: "Order buy",
          ORDER_EXTEND: "Order extend",
          REPLENISHMENT: "Fill balance"
        }
      },
      actionsHistory: {
        heading: "History of actions",
        table: {
          replacementDate: "Replacement Date:",
          replacementTime: "Replacement Time:",
          replacedWith: "Replaced with:"
        }
      },
      promocodes: {
        heading: "Promocodes",
        more: "Show more"
      },
      subscription: {
        heading: "Subscription",
        successMessage: "Subscriptions changed successfully. Please check your email to confirm",
        errorMessage: "Error occured"
      },
      developersApi: {
        heading: "API",
        description: {
          apiKey: "Do not share this key with anyone.",
          accessIpAddresses:
            "API access can only be shares from specified Ip addresses. You can specify up to 3 IP addresses separated by commas. Please note that no more than 60 API requests are allowed within 1 minute. If the limit is exceeded? additional requests will result in an error message."
        },
        documentation: {
          label: "Read documentations",
          read: "Read"
        }
      }
    },
    tools: {
      myIp: {
        heading: "My IP",
        description: `
          <p>
            Hide your IP address with personal proxies from Proxy-IPv4! Protect your personal data
            stay anonymous, hide your location and bypass website blocks with proxy servers!
          </p>
          <p>
            And this is just a small part of the information that can be tracked by your IP
            address:
          </p>
        `,
        seo: "",
        table: {
          yourComputerName: "Your computer name:",
          operatingSystem: "Operating system:",
          yourBrowser: "Your browser:",
          yourLocation: "Your location:",
          yourProvider: "Your provider:",
          proxy: "Proxy:",
          dataProtection: "Data protection:",
          ipInBlacklists: "IP in Blacklists:"
        }
      },
      pingIp: {
        heading: "Ping IP",
        description: `
          <p>
            Click on the «Insert my IP address» button or use the IP or site to which you need to
            check the ping. Select 3-5 test packets and click the «Ping IP» button. You will see
            the desired value in the «time=» section.
          </p>
          <p>
            Ping is considered normal when it is in the range of 40-110ms. From 110-210 ms you you
            will start to experience problems in online games, but on visiting sites it is
            practically not will be reflected. If the timeout exceeds 110 ms, then this is a
            reason to think about the quality of work your provider.
          </p>
        `,
        note: "* If the result is «Host Down», then a firewall or router check is in progress and blocking IP addresses.",
        seo: ""
      },
      checkEvercookie: {
        heading: "Check Evercookie",
        description: `
          <p>
            Click the button to create an evercookie. Your anonymity will not be violated: cookie
            - this is a random number between 1 and 1000 that we use to test evercookies. We do
            not track you with it.
          </p>
        `,
        createEvercookie: "Create Evercookie",
        searchEvercookie: "Search Evercookie",
        seo: ""
      },
      proxyChecker: {
        heading: "Proxy checker",
        description: `
          <p>
            Proxy checker online to check the validity of proxy servers. Proxy or group analysis
            addresses for availability, country, speed, anonymity, type. Proxy checker online for
            checking the validity of proxy servers. Analysis of a proxy or a group of addresses
            for availability, country, speed, anonymity, type.
          </p>
        `,
        proxyList: "Proxy list",
        table: {
          ip: "IP address:",
          port: "Port:",
          country: "Country:",
          speed: "Speed:",
          type: "Type:",
          anonymity: "Anonymity:",
          state: "State:"
        },
        formatNote: {
          label: "Format for adding a proxy to the checker?",
          publicText:
            "If you have <b>public proxies</b> (without username and password), then <b>IP:PORT</b> ",
          privateText:
            "If you have <b>private proxies</b> (with authorization by username and password), then <b>USER:PASS@IP:PORT</b> ",
          psText: "If you bought proxies from us, they are private!"
        },
        seo: ""
      },
      myAnonymity: {
        heading: "My anonymity",
        widgets: {
          caption: "All the sites you've visited identify your data as:"
        },
        description:
          "We can verify the accuracy of this information, whether it is actually true, whether you are using a proxy, an anonymizer, a VPN server, Tor, or other means of anonymization.",
        seo: "",
        table: {
          httpProxyHeaders: "HTTP proxy headers:",
          openHttpProxyPorts: "Open HTTP proxy ports:",
          openWebProxyPorts: "Open web proxy ports:",
          openVpnPorts: "Open VPN ports:",
          suspiciousHostname: "Suspicious hostname:",
          differenceInTimeZonesBrowserAndIp: "Difference in time zones (browser and IP):",
          ipAffiliationWithTheTorNetwork: "IP affiliation with the Tor network:",
          browserTurboMode: "Browser Turbo mode:",
          affiliationWithIpHostingProvider: "Affiliation with IP hosting provider:",
          webProxyDefinitionJsMethod: "Web proxy definition (JS method):",
          tunnelDefinitionTwoWayPing: "Tunnel definition (two-way ping):",
          dnsLeak: "DNS leak:",
          vpnFingerprint: "VPN fingerprint:",
          ipLeakViaWebRTC: "IP leak via WebRTC:",
          ipInBlacklists: "IP in Blacklists:"
        }
      },
      checkWebrtc: {
        heading: "WebRTC Test",
        description:
          "<p>Leak check via WebRTC. Your data may be at risk even if you connected to the VPN.</p>",
        widgets: {
          ip: "IP address:",
          type: "Type:",
          status: "Status:"
        },
        restartTest: "Restart WebRTC leak test",
        table: {
          ip: "IP address:",
          type: "Type:",
          status: "Status:"
        },
        seo: ""
      },
      portScanner: {
        heading: "Port Scanner",
        description: `
          <p>
            Checking open ports with a scanner shows which ports are open on your or someone
            else&apos;s computer/website and what they are responsible for. Checking is done through
            the nmap scanner and shows how open your access to the PC / site is to the outside
            world.Check is done through the nmap scanner and shows how open your access to the pc /
            site is for outside world.
          </p>
        `,
        note: "* If the result is «Host Down», then a firewall or router check is in progress and blocking IP addresses.",
        optionsLabel: "Types of ports to scan:",
        seo: ""
      },
      fingerprintScanner: {
        heading: "Browser Fingerprint Scanner",
        description: `
          <p>
            Browser fingerprints are a unique snapshot of your computer settings, and special
            mechanisms of sites can track you.
          </p>
        `,
        widgets: {
          fingerprint: "Browser Fingerprint:"
        },
        seo: "",
        table: {
          location: "Location:",
          dns: "DNS:",
          provider: "Provider:",
          host: "Host:",
          os: "OS:",
          browser: "Browser:",
          plugin: "Plugin:",
          status: "Status:",
          userAgent: "User Agent:",
          userAgentJs: "User Agent (JS method):"
        }
      },
      ipTracing: {
        heading: "IP Trace",
        description: `
          <p>
            Thanks to the service that is posted on this page, you have access to free and almost
            instant tracing of an IP address or website. She will help you complete network
            diagnostics that determines the speed of sending requests through servers on the
            Internet when opening a web resource in a browser. With the help of tracing, you will be
            able to determine the causes possible problems related to the slow loading of the site.
          </p>
        `,
        optionsLabel: "Show package route by country:",
        seo: ""
      }
    },
    auth: {
      login: {
        heading: "Log in to your account",
        agreementPartOne: "By using the site, you agree to",
        agreementPartSecond: "the privacy policy",
        forgot: "Forgot your password?",
        recoverPassword: "Recover password",
        register: "Register",
        login: "Login"
      },
      register: {
        heading: "Register"
      },
      forgot: {
        heading: "Password Recovery"
      }
    },
    common: {
      error: {
        title: "Oops... Something went wrong.",
        description: `<p>Sorry for the inconvenience, our specialists received a message about this error!</p>
            <p>Don't worry, they will quickly fix everything now :)</p>`
      }
    }
  },
  shared: {
    reviews: {
      heading: "Reviews",
      button: "View all reviews",
      moreButton: "Show more",
      action: "Read Review"
    },
    hasPromocode: "Got a promo code?",
    address: {
      title: "Where We Are"
    },
    contacts: {
      title: "How to contact us"
    },
    socials: {
      title: "Our social networks"
    },
    workingHours: {
      title: "Working Hours",
      aroundTheClock: "Around the clock",
      withoutWeekends: "Without days off"
    },
    cabinet: "Personal account",
    orderModal: {
      title: "Order Form",
      description:
        "IP addresses will be connected automatically and will be displayed in your personal account after payment.",
      ipTooltip: "Only static IP",
      ipValidationError: "This IP cannot be used for authorization"
    },
    faq: {
      heading: "Frequently asked questions",
      noData: "No questions found"
    },
    tools: {
      yourIp: "Your IP:",
      yourAnonymity: "Your anonymity:",
      result: "Verification result:",
      yourGeo: "Your location:",
      warning: "<span>WebRTC is enabled</span> and may reveal your real IP address!"
    },
    table: {
      options: {
        viewComment: {
          label: "View",
          title: "View Comment",
          caption: "Comment",
          error: "Error loading comment",
          empty: "No comment for this ip"
        },
        deleteComment: {
          label: "Delete",
          title: "Delete Comment",
          text: "Are you sure you want to delete the comment?",
          success: "Comment deleted successfully",
          error: "Error deleting comment"
        },
        addComment: {
          label: "Add",
          title: "Adding a Comment",
          editTitle: "Editing a comment",
          success: "Comment added successfully",
          error: "Error adding comment"
        }
      },

      show: "Show"
    }
  },
  currencies: {
    rub: "rub.",
    usdUpper: "$"
  },
  form: {
    labels: {
      proxyType: "Proxy type:",
      country: "Country:",
      rentalPeriod: "Rental Period:",
      proxyCount: "Count:",
      proxyGoal: "Goal:",
      proxyService: "Service:",
      authType: "Authorization Method:",
      paymentType: "Payment Method:",
      name: "Login:",
      email: "Email:",
      oldEmail: "Old email:",
      newEmail: "New email:",
      confirmNewEmail: "Confirm new email:",
      password: "Password:",
      oldPassword: "Old password:",
      newPassword: "New password:",
      confirmNewPassword: "Confirm new password:",
      promocode: "Promocode:",
      selectAll: "Select all",
      ipOrDomain: "Enter an IP address or domain",
      testPingPackages: "Test ping packages",
      verificationResult: "Verification result:",
      commentToIp: "Comment to IP:",
      proxyProtocol: "Protocol:",
      emailLinkSuccess: "Password reset link sent",
      mobileOperator: "Mobile operator:",
      modemRotationMin: "Rotation IP:",
      proxyList: "Proxy list:",
      nonWorking: "Non working",
      domainsInIp: "Domains in IP"
    },
    placeholders: {
      promocode: "Enter promo code",
      noOptions: "No options",
      firstSelectCountry: "Select a country",
      firstSelectMobileOperator: "Select a mobile operator",
      yourEmail: "Your email",
      enterPassword: "Enter password",
      enterEmail: "Enter email",
      newPassword: "New password",
      confirmNewPassword: "Confirm new password",
      selectPaymentMethod: "Choose the payment method",
      paymentMethod: "Payment method",
      enterCustomGoal: "Purpose of use",
      selectOperator: "Select operator",
      selectRentPeriod: "Choose a rent period",
      selectRotation: "Choose ip rotation",
      selectProtocol: "Choose protocol",
      personalExportPattern: "Personal upload format",
      yourName: "Your name",
      companyName: "Company name",
      emailOrOther: "E-mail or other"
    },
    actions: {
      login: "Login",
      register: "Register",
      buyProxy: "Buy Proxy",
      makeOrder: "Make order",
      sendMessage: "Send message",
      changePassword: "Change password",
      changeEmail: "Change email",
      applyChange: "Apply change",
      extend: "Extend",
      startScan: "Start Scan",
      cancel: "Cancel",
      delete: "delete",
      add: "Add",
      restore: "Restore",
      create: "Create",
      edit: "Edit",
      goToMainPage: "Go to main page",
      submit: "Submit",
      generateKey: "Generate key"
    },
    rules: {
      name: {
        required: "Login required",
        invalid: "Incorrect login"
      },
      email: {
        required: "Mail required",
        invalid: "Invalid mail",
        match: "Emails don't match"
      },
      password: {
        required: "Password required",
        min: "Minimum characters",
        match: "Passwords not match",
        notAllowed: "Passwords contain not allowed symbols",
        invalid: "Invalid password",
        passwordContainsSpaces: "Password contains spaces"
      },
      apiKey: {
        required: "API Key required"
      },
      accessIpAddresses: {
        invalid: "Invalid IP address",
        maxCount: "You can specify up to 3 IP addresses"
      },
      ipOrDomain: {
        required: "IP or domain required",
        invalid: "Invalid IP or domain"
      },
      proxyList: {
        required: "A list of proxies is required",
        invalid: "Invalid proxies list",
        copiesFound: "Copies found"
      },
      quantity: {
        minQuantity: "Minimum quantity {{quantity}}"
      },
      personalExportPattern: {
        required: "Personal upload format required",
        requiredVariables: "Required variables",
        atLeastOneVariableMustBeUsed: "At least one variable must be used",
        invalidVariables: "Invalid variables"
      },
      common: {
        hasUrl: "The field contains a URL",
        required: "Field is required",
        min: "Field must be at least {{min}} characters",
        max: "The field should not exceed {{max}} characters",
        oneOf: "Field must be one of the following values"
      }
    },
    serverErrors: {
      balance: "Not enough funds",
      promocode: "Promocode is not valid",
      default: "An error occured",
      minQuantityTen: "Minimum quantity for order is 10",
      ips: "Can't extend expired proxies",
      minPrice: "The minimum price for the payment system is {{price}}",
      minAmountForPaymentSystem: "Minimum amount for payment system is {{amount}}",
      incorrectAmount: "Incorrect amount",
      incorrectPaymentSystem: "Incorrect payment system",
      incorrectLanguageCode: "Incorrect language code",
      incorrectOldPassword: "The old password is incorrect",
      incorrectNewPassword: "The new password is incorrect",
      incorrectGoal: "Incorrect purpose of use",
      passwordEquals: "The new password must be different from the old one",
      incorrectEmail: "Please enter an existing email"
    }
  },
  other: {
    piece: "IP",
    ips: "IPs",
    pieces: "pieces",
    from: "From",
    discount: "Discount:",
    price: "Cost:",
    totalSum: "Total amount:",
    countPiece: "Quantity:",
    selected: "Selected",
    proxy: "proxies",
    logout: "Logout",
    showAll: "Show all",
    byLink: "By link",
    insertList: "Insert as a list",
    insertListDescription: "Write a list of IPs you want to renew",
    apply: "Apply",
    cancel: "Cancel",
    noMatchesIp: "No matches found",
    foundIp: "Found {{count}} IPs",
    foundIpFrom: "Found {{endCount}} out of {{startCount}} entered IPs"
  },
  export: {
    ownFormat: "Own format",
    txt: "txt",
    excel: "excel",
    window: "New window"
  },
  days: "days",
  copyMessageSuccess: "Successfully copied",
  copyMessageError: "Failed to copy",
  passwordChangeSuccess: "Password changed succesfully",
  loginUnauthorized: "Incorrect login data",
  serverError: "Server error",
  userNotFound: "User not found",
  userExist: "User already exist",
  registerSuccess: "Registration complete",
  orderCreateError: "Order creating error. Try again",
  ipAddressesSaved: "IP addresses saved",
  repayError: "An error occured processing the payment",
  order: {
    messages: {
      success: {
        title: "Order is processed",
        description:
          "You have successfully placed an order, and here there will be information on the second line."
      },
      error: {
        title: "Purchase failed",
        description:
          "Something went wrong, for reasons beyond our control, the order was not processed."
      }
    }
  },
  common: {
    goBack: "Go back",
    tryAgain: "Try again",
    iAgree: "I agree",
    toTheTerms: "to the terms",
    notUsed: "Not used",
    used: "Used",
    absent: "Absent",
    noData: "No data",
    active: "Active",
    notActive: "Not active",
    on: "On",
    off: "Off",
    cancel: "Cancel",
    canceled: "Canceled",
    timeout: "Timeout",
    potentialLeak: "Potential leak",
    leakageThreat: "Leakage threat",
    potentialThreat: "Potential threat",
    threatsAreAbsent: "Threats are absent",
    browser: "Browser",
    yes: "Yes",
    no: "No",
    detected: "Detected",
    notDetected: "Not detected",
    copy: "Copy",
    millisecondShort: "ms.",
    shown: "Shown",
    postscriptShort: "P.S.",
    read: "Read",
    order: "Order",
    quantity: "Quantity",
    export: "Export",
    example: "Example",
    availableVariables: "Available variables",
    byLink: "By link",
    byTime: "By time",
    rotation: "Rotation",
    contactUs: "Contact us",
    enterprise: "Enterprise",
    other: "Other",
    email: "Email",
    maxCharacters: "Max. {{max}} characters"
  },
  discountOfferTooltip: {
    addButton: "Add",
    discountOfferText: "{{count}} IP, it's {{discountInPercent}}% cheaper"
  },
  messages: {
    logInWithYourPreviousCredentials: "Log in with your previous credentials",
    linkToTheChangeHasBeenSentByEmail: "A link to the change has been sent by email",
    emailAlreadyInUse: "E-mail is already in use",
    emailChanged: "E-mail changed",
    noUserFoundWithThisEmail: "No user found with this email",
    userNotFoundByHash: "User not found by hash",
    newEmailEqualsUserEmail: "New email equals user email",
    templateSaved: "Template saved",
    failedToSaveTemplate: "Failed to save template",
    failedToLoadTemplate: "Failed to load template",
    captchaFailed: "Failed to complete CAPTCHA",
    temporaryEmails: "Sorry, temporary emails cannot be used.",
    pleaseResubmitTheForm: "Please resubmit the form",

    promoCodeNotExist: "Promo code does not exist",
    promoCodeNotActive: "Promo code is not active",
    promoCodeInvalid: "Promo code is invalid",
    promoCodeConditionInvalid: "Promo code does not meet the conditions"
  }
};
